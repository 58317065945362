<!-- 用户管理页面 -->
<template>
  <div class="userManager">
    <div class="head">
      <el-input size="mini" placeholder="用户昵称搜索" v-model="accountKwd" style="width: 25rem; border-radius: 20px"
        @keyup.enter.native="getuserData">
        <i slot="prefix" class="el-input__icon el-icon-search" @click="getuserData"></i>
      </el-input>
      <el-button style="margin-left: 10px;" round size="mini" @click="exportzbPage()">导出用户数据</el-button>
    </div>
    <div class="body">
      <el-table :data="userData" :key="tableKey" id="user" style="width: 100%; font-size: 0.8rem; flex: 1" height="100%"
        v-loading="loading">
        <el-table-column label="序号" type="index" width="80" align="center" :show-overflow-tooltip="true">
        </el-table-column>

        <el-table-column label="用户名" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.account }}
          </template>
        </el-table-column>
        <el-table-column label="手机号" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.mobile }}
          </template>
        </el-table-column>
        <el-table-column label="支付宝姓名" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.alipayName }}
          </template>
        </el-table-column>
        <el-table-column label="支付宝账号" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.alipayAccount }}
          </template>
        </el-table-column>
        <el-table-column label="全部参与数" width="100" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.totalNum }}
          </template>
        </el-table-column>
        <el-table-column label="已提交数" width="100" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.submittedNum }}
          </template>
        </el-table-column>
        <el-table-column label="已通过数" :show-overflow-tooltip="true" width="100">
          <template slot-scope="scope">
            {{ scope.row.finishedNum }}
          </template>
        </el-table-column>
        <el-table-column label="未提交数" align="center" width="100">
          <template slot-scope="scope">
            {{ scope.row.notSubmittedNum }}
          </template>
        </el-table-column>

        <el-table-column label="总收益" align="center" width="100">
          <template slot-scope="scope"> {{ scope.row.income }}元 </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <span style="cursor: pointer" @click="godetails(scope.row.id)">查看详情</span></template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="prev, pager, next" :total="total"
      style="margin-left: AUTO; margin-top: 10px; display: block" :pager-count="7" @current-change="getuserData"
      :current-page.sync="pageNo" :page-size="pageSize">
    </el-pagination>
  </div>
</template>

<script>
import router from "@/router";
import store from "@/store";
import { getuserInfo, exportzbPage } from "@/api/user";
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
  components: {},
  data() {
    return {
      accountKwd: "",
      userData: [],
      pageNo: 1,
      pageSize: 10,
      tableKey: true,
      loading: false,
      total: 0,
    };
  },
  created() { },
  mounted() {
    this.getuserData();
  },
  watch: {},
  computed: {},
  methods: {
    async getuserData() {
      await getuserInfo({
        accountKwd: this.accountKwd,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        this.tableKey = !this.tableKey;
        this.userData = res.data.records;

        this.total = res.data.total
      });
    },
    godetails(val) {
      store.commit("user/CHANGE_userid", val);
      router.push({
        name: "用户管理详情页",
      });
    },
    // 导出用户数据
    async exportzbPage() {
      await exportzbPage({}).then(res => {
        const a = document.createElement('a');
        a.href = res.data;
        document.body.appendChild(a);
        a.click();
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.userManager {
  color: black;
  overflow: auto;
  padding: 1rem 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .head {
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep .el-input--mini .el-input__inner {
      border-radius: 2rem;
    }
  }

  .body {
    margin-top: 10px;
    flex: 1;
    overflow: auto;
  }
}
</style>