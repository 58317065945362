/*
 * @Author: dingguowei
 * @Date: 2023-06-13 14:41:45
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-06 16:57:10
 */
// 用户端接口
import request from '@/plugins/axios'
const base = '/'

// 用户端获取任务大厅任务
export function userGetTask(data) {
    return request({
      url:  base + 'api/zbMission/user/page',
      method: 'post',
      data
    })
}

// 用户任务详情
export function gettaskInfo(params) {
  return request({
    url:  base + 'api/zbMission/get',
    method: 'get',
    params
  })
}

// 用户获取拉黑和拒绝审批结果
export function getauditresult(params) {
  return request({
    url:  base + 'api/zbMissionReceive/user/get',
    method: 'get',
    params
  })
}

// 用户领取任务
export function usersave(data) {
  return request({
    url:  base + 'api/zbMissionReceive/save',
    method: 'post',
    data
  })
}

// 用户领取任务
export function usercommit(data) {
  return request({
    url:  base + 'api/zbMissionReceive/commit',
    method: 'post',
    data
  })
}

// 用户查看已领取任务
export function usergetselfTask(data) {
  return request({
    url:  base + 'api/zbMissionReceive/self/page',
    method: 'post',
    data
  })
}

// 用户查看提交统计
export function userGetNum(params) {
  return request({
    url:  base + 'api/zbMissionReceive/user/stat',
    method: 'get',
    params
  })
}

// 用户查看生成的文案
export function getcontent(params) {
  return request({
    url:  base + 'api/zbMissionPromptRecord/user/get',
    method: 'get',
    params
  })
}

// 管理员查看改用户提交统计
export function adminGetNum(params) {
  return request({
    url:  base + 'api/userInfo/user/stat',
    method: 'get',
    params
  })
}



// 用户查看领取的任务
export function userTask(data) {
  return request({
    url:  base + 'api/zbMissionReceive/user/page',
    method: 'post',
    data
  })
}

// 管理员查看领取的任务
export function administratorsGetuserinfo(data) {
  return request({
    url:  base + 'api/userInfo/detail/zbPage',
    method: 'post',
    data
  })
}

// 用户关闭领取的任务
export function userclose(data) {
  return request({
    url:  base + 'api/zbMissionReceive/close',
    method: 'post',
    data
  })
}

// 用户查看钱包信息分页
export function zbWallet(data) {
  return request({
    url:  base + 'api/zbWalletRecord/page',
    method: 'post',
    data
  })
}

// 用户查看钱包金额
export function getmoney(params) {
  return request({
    url:  base + 'api/zbWalletRecord/get',
    method: 'get',
    params
  })
}

// 用户提交平台信息
export function sysUserSocial(data) {
  return request({
    url:  base + 'api/sysUserSocialVerify/save',
    method: 'post',
    data
  })
}

// 用户修改平台信息
export function upsysUserSocial(data) {
  return request({
    url:  base + 'api/sysUserSocialVerify/update',
    method: 'post',
    data
  })
}

// 用户修改支付宝微信等支付信息
export function updatezbWallet(data) {
  return request({
    url:  base + 'api/zbWallet/update',
    method: 'post',
    data
  })
}



// 品牌方查看用户详情
export function getuserInfo(data) {
  return request({
    url:  base + 'api/userInfo/zbPage',
    method: 'post',
    data
  })
}

// 用户查看社交媒体信息
export function userInfozbget(params) {
  return request({
    url:  base + 'api/userInfo/zb/get',
    method: 'get',
    params
  })
}


// 用户任务看板查看任务提交统计
export function getuserstat(params) {
  return request({
    url:  base + 'api/front/user/stat',
    method: 'get',
    params
  })
}


// 用户任务看板查询任务列表
export function getusermissionpage(data) {
  return request({
    url:  base + 'api/front/mission/page',
    method: 'post',
    data
  })
}


/*************************** 个人信息修改 **********************/
  // 获取用户信息
  export function usergetpersonal(params) {
    return request({
      url:  base + 'api/userInfo/get',
      method: 'get',
      params
    })
}
  
// 修改用户信息
export function update(data) {
  return request({
    url:  base + 'api/userInfo/update',
    method: 'post',
    data
  })
}

// 获取用户邀请码记录
export function getinvitation(data) {
  return request({
    url:  base + 'api/invitation/self/page/get',
    method: 'post',
    data
  })
}

// 获取用户邀请码规则
export function getinvitationrules(params) {
  return request({
    url:  base + 'api/invitationReward/list',
    method: 'get',
    params
  })
}
  // 修改密码
  export function resetpassword(data) {
    return request({
      url:  base + 'api/userInfo/password/reset',
      method: 'post',
      data
    })
}

// 用户端查看用户文档
export function sysDocument(params) {
  return request({
    url:  base + 'api/sysDocument/list',
    method: 'get',
    params
  })
}

// 管理端导出用户数据
export function exportzbPage(data) {
  return request({
    url:  base + 'api/userInfo/zbPage/export',
    method: 'post',
    data
  })
}








